import * as React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SocialLink from "../components/socialLink"

const BioPage = ({data, location}) => {
  const author = data.site.siteMetadata?.author
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="biography" />
      <div className="main-bio">
        <h5 className="bio-author">{author.name}</h5>
        <p>{author?.summary || null}</p>
        <p className="bio-summary">{author?.summary2 || null}</p>
        <SocialLink />
      </div>
    </Layout>
  )
}

export default BioPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author {
          name
          summary
          summary2
        }
        social {
          twitter
        }
      }
    }
  }
`
